// entry.client.js
import createApp from './src/app.js';

const { app, router } = createApp();

router.onReady(() => {
  updateMeta(router.currentRoute);

  router.beforeEach((to, from, next) => {
    updateMeta(to);
    next();
  });

  app.$mount('#app');
});

function updateMeta(route) {
  const meta = route.meta || {};
  const defaultDescript = `What is GB WhatsApp and what's new in GBWhatsApp Latest version after Ban Wave? Visit our site to learn more about it and download the apk safely.`
  const defaultKeyword = 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download'
  document.title = meta.title || 'Download GB WhatsApp Latesed Version after Ban Wave ';

  setMetaTag('description', meta.description || defaultDescript);
  setMetaTag('og:description', meta.description || defaultDescript);
  setMetaTag('keywords', meta.keywords || defaultKeyword);
}

function setMetaTag(name, content) {
  let element = document.querySelector(`meta[name="${name}"]`);
  if (element) {
    element.setAttribute('content', content);
  } else {
    element = document.createElement('meta');
    element.setAttribute('name', name);
    element.setAttribute('content', content);
    document.head.appendChild(element);
  }
}